export default {
    name: "ListaEvento",
    data() {
        return {
            arEventos: [],
            blLoading: true,
            errorMsg: null,
            successMsg: "",
            obForm: {
                texto: ""
            }
        };
    },
    created() {
        this.listar();
        this.buscarTitulo();
    },
    methods: {
        listar() {
            this.$root.$api.get("evento/listar").then(response => {
                this.arEventos = response.retorno;
                this.blLoading = false;
            });
        },
        buscarTitulo() {
            this.$root.$api.get("evento/titulo").then(response => {
                this.obForm.texto = response.retorno.texto;
            });
        },
        salvarTextoCalendario() {
            if(this.obForm.texto.trim() == "" || this.obForm.texto.length > 56) {
                this.errorMsg = "O texto deve ter no máximo 56 caracteres";
                this.successMsg = "";
            }else {
                this.errorMsg = null;
                this.$root.$api.post("evento/salvarTitulo",this.obForm).then(response => {
                    this.successMsg = "Texto alterado com sucesso";
                });
            }
        }
    }
};
